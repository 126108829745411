import { Link } from "gatsby"
import React from "react"
import Image from "./image"

class SeoHero extends React.Component {

  render() {

    return (
      <div id="hero-push">
        <div id="hero-container">
          <div id="hero">
            <div className="hero-text-container">
              <h1>{this.props.title}</h1>
              <h2 className="h2-smaller">{this.props.description}</h2>
            </div>
            <div className="hero-button-container">
              <a href="https://app.mtgevent.com/signup" className="button primary">Get started</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SeoHero
