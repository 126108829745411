import { Link } from "gatsby"
import React from "react"
import Image from "./image"

import "./hamburger.css"


class GetStartedSteps extends React.Component {

  render() {
    return (
      <div className="get-started">
        <div className="container">
          <h2>Create your first Magic tournament</h2>
          <p>With MTGevent.com you'll get your first Magic: The Gathering tournament started in no time!</p>
          <div className="step">
            <div className="left">
              <span className="step-number">1</span>
              <Image filename="step-1" alt="Create your free account at MTGevent.com" className="step-img" />
            </div>
            <div className="right">
              <h3>Sign up to MTGevent.com</h3>
              <p>Start by creating a free account at MTGevent.com</p>
              <a href="https://app.mtgevent.com/signup" className="button primary">Create your account</a>
            </div>
          </div>
          <div className="step">
            <div className="left">
              <span className="step-number">2</span>
              <Image filename="step-2" alt="Create your free account at MTGevent.com" className="step-img" />
            </div>
            <div className="right">
              <h3>Create your first Magic: The Gathering event</h3>
              <p>Getting your first event created is as easy as clicking a button. When your event is created, you can easily add players by simply entering their name, or share your event with your friends and playgroup and let them join with their own accounts.</p>
            </div>
          </div>
          <div className="step">
            <div className="left">
              <span className="step-number">3</span>
              <Image filename="step-3" alt="Create your free account at MTGevent.com" className="step-img" />
            </div>
            <div className="right">
              <h3>Start the tournament and play some Magic!</h3>
              <p>This is where the Magic happens! With MTGevent.com, you can focus more on beating your opponents and less on managing your event. MTG Event handles pods, pairings, results and tie breakers for you in a simple and intuitive way.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GetStartedSteps
