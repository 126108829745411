import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image";

import SeoHero from "../components/seoHero"
import GetStartedSteps from "../components/getStartedSteps"
import CustomStructuredText from "../components/customStructuredText"

const Page = ({ data }) => {
  console.log(data)
  const { title, seo, content } = data.datoCmsPage;
  return (
    <Layout>
      <SEO title={title} description={seo.description} />
      <SeoHero title={title} description={seo.description} />
      <div className="page container">
        <Image filename="devices" alt={title} className="seo-devices" />
        <CustomStructuredText
          data={content}
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsAmazonNativeShoppingGrid":
                return (
                  <div id="amazon-ad"></div>
                );
              default:
                return null;
            }
          }}
        />
        <GetStartedSteps />
      </div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      title
      slug
      seo {
        description
      }
      content {
        value
      }
    }
  }
`;
